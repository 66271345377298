import React from 'react';

export const List = ({ type, children }) => {
  const isOrdered = type === 'wrapper-ordered-list-item';

  if (isOrdered) {
    return <ol style={{ listStyle: 'decimal', fontSize: 14 }}>{children}</ol>;
  }

  return <ul style={{ listStyle: 'disc', fontSize: 14 }}>{children}</ul>;
};
