export const isAValidDraftJson = (json = {}) => json.blocks && json.entityMap;

export const isAValidDraftBlock = (block = {}) => block.text && block.type;

const ascOffset = (a = {}, b = {}) => {
  if (a.offset > b.offset) {
    return 1;
  }

  return -1;
};

export const replaceAt = (offset, length, string, target) => {
  const stringBeforeTarget = string.substring(0, offset);
  const stringAfterTarget = string.substring(offset + length, string.length);

  return `${stringBeforeTarget}${target}${stringAfterTarget}`;
};

const removeDuplicatedEntities = entities => {
  const sortedItems = entities.sort(ascOffset);

  const sanitizedItems = sortedItems.reduce(
    (acc, current) => {
      const lastRange = acc.last.offset + acc.last.length;

      if (lastRange === 0 || current.offset > lastRange) {
        return { ...acc, items: [...acc.items, current], last: current };
      }

      return acc;
    },
    { items: [], last: { offset: 0, length: 0 } }
  );

  return sanitizedItems.items;
};

export const mergeEntities = (block, entityData) => {
  const entitiesRanges = block.entityRanges;
  const styleRanges = block.inlineStyleRanges;

  const parsedEntities = entitiesRanges.map(entity => {
    const { offset, length, key = '' } = entity;
    const { type, data } = entityData[key];

    return {
      offset,
      length,
      style: type,
      data,
    };
  });

  return removeDuplicatedEntities([...styleRanges, ...parsedEntities]);
};

const formatStyle = (entity, blockText) => {
  const { offset, length, style: type, data: props = {} } = entity;
  const entityText = blockText.substring(offset, offset + length);

  return { text: entityText, type, props };
};

const removeEmptyText = (textBlock = []) => textBlock.filter(item => item !== '');

const mergeTextAndStyles = block => {
  const textBlocks = removeEmptyText(block.text.split(/({\d+})/));
  const { styles } = block;

  return textBlocks.map(entity => {
    const style = entity.match(/^{(\d+)}$/) || [];
    const styleKey = style[1] || null;

    if (!styleKey) {
      return entity;
    }

    return styles[styleKey];
  });
};

export const parseBlock = (block, entityData) => {
  if (!isAValidDraftBlock(block)) {
    return null;
  }

  const blockText = block.text;
  const entities = mergeEntities(block, entityData);

  const parsedBlock = entities.reduce(
    (acc, entity, index) => {
      const { offset, length } = entity;
      const offsetIncrease = offset - (blockText.length - acc.text.length);
      const style = formatStyle(entity, blockText);

      return {
        text: replaceAt(offsetIncrease, length, acc.text, `{${index}}`),
        styles: [...acc.styles, style],
      };
    },
    { text: blockText, styles: [] }
  );

  return {
    text: mergeTextAndStyles(parsedBlock),
    type: block.type,
    data: block.data,
  };
};

export const enqueueEntity = (parsedBlock, allBlocks, acc, index) => {
  const { type } = parsedBlock;
  const nextEntity = allBlocks[index + 1] || {};
  const shouldFinishQueue = nextEntity.type !== type;
  const queue = [...acc.queue, parsedBlock];

  if (shouldFinishQueue) {
    const block = {
      type: `wrapper-${type}`,
      children: queue,
    };

    return {
      draft: [...acc.draft, block],
      queue: [],
    };
  }

  return {
    draft: acc.draft,
    queue,
  };
};

export const parseJson = data => {
  try {
    const json = JSON.parse(data);

    return json;
  } catch (error) {
    return null;
  }
};

export const parseDraft = draftJson => {
  if (!isAValidDraftJson(draftJson)) {
    return null;
  }

  const { blocks: allBlocks, entityMap: entityData } = draftJson;

  const parsedBlocks = allBlocks.reduce(
    (acc, block, index) => {
      const blockType = block.type;
      const parsedBlock = parseBlock(block, entityData) || [];
      const shouldEnqueueEntity = blockType === 'unordered-list-item' || blockType === 'ordered-list-item';

      if (shouldEnqueueEntity) {
        return enqueueEntity(parsedBlock, allBlocks, acc, index);
      }

      return {
        draft: acc.draft.concat(parsedBlock),
        queue: acc.queue,
      };
    },
    { draft: [], queue: [] }
  );

  return parsedBlocks.draft;
};
