import React from 'react';

export const Title = ({ type, children }) => {
  switch (type) {
    case 'header-one':
      return <h1 style={{ fontSize: '22px', fontWeight: 'bold' }}>{children}</h1>;
    case 'header-two':
      return <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>{children}</h2>;
    case 'header-three':
      return <h3 style={{ fontSize: '16px', fontWeight: 'bold' }}>{children}</h3>;
  }

  return <h1 style={{ fontSize: '22px', fontWeight: 'bold' }}>{children}</h1>;
};
