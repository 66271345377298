import React from 'react';
import styled from 'styled-components';
import { getComponent } from './components';
import { parseDraft, parseJson } from './helpers/parse';

const ViewUI = styled.div`
  margin-bottom: 15px;
  & * {
    color: ${({ colorText }) => (colorText ? colorText : '#666')};
  }
`;

const renderDraftInline = block => {
  const { children, text } = block;

  if (children) {
    return renderDraftBlocks(children, true);
  }

  return text.map((entity, index) => {
    if (typeof entity === 'string') {
      return entity;
    }

    const { props, type, text: entityText } = entity;
    const WrapperComponent = getComponent(type);
    const key = `${type}${index}`;

    return (
      <WrapperComponent key={key} type={type} {...props}>
        {entityText}
      </WrapperComponent>
    );
  });
};

const renderDraftBlocks = (blocks, colorText) =>
  blocks.map((block, index) => {
    const { type, data: styles } = block;
    const key = `${type}${index}`;
    const WrapperComponent = getComponent(type);

    return (
      <ViewUI key={key} colorText={colorText}>
        <WrapperComponent type={type} styles={styles}>
          {renderDraftInline(block)}
        </WrapperComponent>
      </ViewUI>
    );
  });

const renderDraft = (draftJson, colorText) => {
  try {
    const draftData = parseDraft(draftJson);

    return renderDraftBlocks(draftData, colorText);
  } catch (error) {
    console.error('Draft parse has failed.', error);
    return null;
  }
};

const DraftView = ({ contentJson, colorText }) => {
  if (!contentJson) {
    return null;
  }

  const rawContent = typeof contentJson !== 'string' ? JSON.stringify(contentJson) : contentJson;

  return renderDraft(parseJson(rawContent), colorText);
};

export { DraftView };
