import { Paragraph } from './paragraph';
import { List } from './list';
import { ListItem } from './list-item';
import { Title } from './title';
import { Text } from './text';
import { Link } from './link';

export const getComponent = type => {
  switch (type) {
    case 'unstyled':
      return Paragraph;
    case 'header-one':
    case 'header-two':
    case 'header-three':
      return Title;
    case 'LINK':
      return Link;
    case 'unordered-list-item':
    case 'ordered-list-item':
      return ListItem;
    case 'wrapper-unordered-list-item':
    case 'wrapper-ordered-list-item':
      return List;
    default:
      return Text;
  }
};
