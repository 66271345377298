import React from 'react';

export const Text = ({ type, children }) => {
  switch (type) {
    case 'text-small':
      return (
        <span className="text" style={{ fontSize: '12px' }}>
          {children}
        </span>
      );
    case 'BOLD':
      return <strong className="text">{children}</strong>;
    case 'ITALIC':
      return <i className="text">{children}</i>;
    default:
      return (
        <span className="text" style={{ fontSize: '14px' }}>
          {children}
        </span>
      );
  }
};
