import React from 'react';

export const Paragraph = ({ styles = {}, children }) => {
  const handleTextAlign = styles.textAlign || styles.align;
  return (
    <p
      className="text"
      style={{
        textAlign: handleTextAlign ? handleTextAlign : 'initial',
        fontSize: 14,
      }}
    >
      {children}
    </p>
  );
};
