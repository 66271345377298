import React, { useState } from 'react';
import { getIn } from 'seamless-immutable';
import { DraftView } from '@catalogo/theme-draft-view';
import styled, { css } from 'styled-components';

const ZionRichText = ({ publication }) => {
  const [seeMore, setSeeMore] = useState(true);
  const cutInDesktop = getIn(publication, ['cutInDesktop'], null);
  const contentJson = getIn(publication, ['contentJson'], '');

  if (!publication) {
    return null;
  }

  return (
    <>
      <Description seeMore={seeMore} cutNumber={parseInt(cutInDesktop)}>
        <DraftView colorText={publication?.colorText} contentJson={contentJson} />
      </Description>
      {!!cutInDesktop && (
        <SeeMore onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'mostrar mais' : 'mostrar menos'}</SeeMore>
      )}
    </>
  );
};

export default ZionRichText;

const Description = styled.div`
  ${({ cutNumber, seeMore }) => css`
    padding-top: 0px;
    font-size: 14px;
    color: #666;
    ${seeMore &&
    css`
      & div:nth-child(1n + ${cutNumber + 1}) {
        display: none;
      }
    `}
  `}
  a {
    text-decoration: underline;
  }
`;
const SeeMore = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: 14px;
  color: #666;
  padding: 0;
  margin: 0 auto;
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  display: inherit;
`;
